/* eslint-disable no-else-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-filename-extension */
import eurekaMgrs from '@eureka/ui-managers';
import '@ui5/webcomponents-icons/dist/activity-individual';
import '@ui5/webcomponents-icons/dist/documents';
import '@ui5/webcomponents-icons/dist/home';
import '@ui5/webcomponents-icons/dist/json-imports/Icons';
import '@ui5/webcomponents-icons/dist/kpi-corporate-performance';
import '@ui5/webcomponents-icons/dist/receipt';
import '@ui5/webcomponents-icons/dist/check-availability';
import '@ui5/webcomponents-icons/dist/history';
import '@ui5/webcomponents-icons/dist/combine';
import '@ui5/webcomponents-icons/dist/developer-settings';
import '@ui5/webcomponents-icons/dist/settings';
import {
  MessageBox,
  MessageBoxActions,
  MessageBoxTypes,
  SideNavigation,
  SideNavigationItem,
  SideNavigationSubItem,
} from '@ui5/webcomponents-react';
import eureka from 'eureka';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './redux/actions';
import { checkPermissions } from './Utils';
import { createPortal } from 'react-dom';
import { getFeatureToggle } from './UserProfile/eureka';
import { useLocation, useNavigate, useParams } from 'react-router';
import { matchRoutes } from 'react-router-dom';

const { eventBus } = eurekaMgrs;
const { getConfig } = eurekaMgrs.ConfigManager;
const { useTranslation } = eureka.I18nProvider;

// react-jss style setup
const disabledColor = 'rgba(205, 206, 207, 0.8)';
const seletedColor = '#0854a0';
const enableColor = '#32363a';
const styles = {
  sideNavigation: {
    overflowY: 'auto',
    '& > div': {
      overflow: 'hidden',
    },
    '--_ui5_list_item_title_size': 'var(--sapFontSize)',
    '--_ui5_side_navigation_icon_color': '#223548',
    '--_ui5-tree-indent-step': '1.75rem',
  },
  disabled: {
    color: disabledColor,
    backgroundColor: 'transparent !important',
    borderLeft: `4px solid transparent`,
    cursor: 'initial',
    '& ui5-icon': {
      color: disabledColor,
    },
    '& span': {
      color: disabledColor,
    },
  },
  enabled: {
    borderLeft: `4px solid transparent`,
    // backgroundColor: '#e5f0fa',
    '& ui5-icon': {
      color: enableColor,
    },
    '&[selected=true]': {
      borderLeft: `4px solid ${seletedColor}`,
      '& ui5-icon': {
        color: seletedColor,
      },
      '& span': {
        color: seletedColor,
      },
    },
  },
  customized: {
    '& >span:nth-child(3)': {
      position: 'absolute',
      right: 0,
      height: '48px',
      width: '240px',
      boxSizing: 'border-box',
      '& ui5-icon': {
        right: '13px',
        top: '16px',
        position: 'absolute',
      },
    },
  },
};
const useStyles = createUseStyles(styles);

function SidePanel(props) {
  const [selectedId, setSelectedId] = useState('');
  const { t } = useTranslation();
  const { common } = props;
  const classes = useStyles();
  const [currentUserPermissions, setCurrentUserPermissions] = useState([]);
  const { config } = props;
  const [isNavBlocked, setIsNavBlocked] = useState(false);
  const [navigationWarningMessage, setNavigationWarningMessage] = useState(null);
  const [navigationWarningButtons, setNavigationWarningButtons] = useState({});
  const [showNavigationWarning, setShowNavigationWarning] = useState(false);
  const [isNotificationWarning, setIsNotificationWarning] = useState(false);
  const [lastSelectedId, setLastSelectedId] = useState(null);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    eventBus.on(
      'disable-navigation',
      ({ value, message, confirmButton, rejectButton, isNotification }) => {
        setIsNavBlocked(value);
        setNavigationWarningMessage(message);
        setNavigationWarningButtons({
          CONFIRM: confirmButton || MessageBoxActions.OK,
          REJECT: rejectButton || MessageBoxActions.Close,
        });
        setIsNotificationWarning(isNotification);
      },
    );
    return () => {
      eventBus.detach('disable-navigation');
    };
  }, []);

  // eslint-disable-next-line no-prototype-builtins
  const isDisable = (item) => item.hasOwnProperty('disabled') && item.disabled;
  const getNavItem = (nav, propName, propValue) => {
    let result;
    if (nav[propName] && nav[propName] === propValue) {
      result = nav;
    } else if (nav?.items?.length) {
      result = nav.items.find((item) => item[propName] && item[propName] === propValue);
    }
    return result;
  };

  const getConfigValueByProp = useCallback(
    (propName, propValue, valueName) => {
      if (!config?.components) return null;
      let result;

      config.components.forEach((comp) => {
        if (!comp.config.sidenav) return;
        comp?.config?.sidenav?.forEach((nav) => {
          const navItem = getNavItem(nav, propName, propValue);
          if (navItem) {
            result = { ...navItem, app: comp.config.app };
          }
        });
      });

      return {
        appName: result.app,
        [valueName]: result[valueName],
        disabled: isDisable(result),
      };
    },
    [config],
  );
  // Need to get all route patterns in routes config format to match current location
  const getAllRoutes = useCallback(() => {
    if (config && config.components) {
      return config.components
        .reduce((acc, component) => [...acc, ...(component?.config?.routers || [])], [])
        .map((route) => ({ path: route, exact: true }));
    }
    return [];
  }, [config]);

  useEffect(() => {
    const routes = getAllRoutes();
    const match = matchRoutes(routes, location.pathname);
    const pathname = match?.[0]?.route?.path || '/';
    if (pathname !== '/') {
      // split the route to get the main path in order to keep the navigation tab highlighted
      // otherwise it defaults to home
      // example if path is http://localhost:2020/osta-sales-audit/transactions/:id then
      // eslint-disable-next-line max-len
      // we match the highlighted nav as if it was http://localhost:2020/osta-sales-audit/transactions
      const routeSplitBySemiColon = pathname.split(`/:`) || null;
      const mainRoute = routeSplitBySemiColon ? routeSplitBySemiColon[0] : pathname;

      const cfg = getConfigValueByProp('router', mainRoute, 'id');
      if (cfg && cfg.id) {
        setSelectedId(cfg.id);
      } else {
        setSelectedId('home');
      }
    }
  }, [getAllRoutes, getConfigValueByProp, location.pathname, params.id, props]);

  useEffect(() => {
    const permissions = getConfig('CurrentUserPermissions') || [];
    setCurrentUserPermissions(permissions);
  }, []);

  const handleSelectChange = (_selectedId, _isNavBlocked) => {
    if (!_selectedId) {
      return;
    }
    if (_isNavBlocked) {
      setLastSelectedId(_selectedId);
      setShowNavigationWarning(true);
      return;
    }
    setSelectedId(_selectedId);
    const cfg = getConfigValueByProp('id', _selectedId, 'router');
    if (cfg && cfg.router && cfg.router !== '#' && !cfg.disabled) {
      navigate(cfg.router);
    }
  };
  const handleNavigationItemClick = (e) => {
    const { selected } = e.target;
    if (!selected) {
      return;
    }
    handleSelectChange(e.target.id, isNavBlocked);
  };

  const handleNavigationWarningClose = (e) => {
    eventBus.emit('confirm-navigation', null, e);
    if (e.detail.action === navigationWarningButtons.CONFIRM || isNotificationWarning) {
      setIsNavBlocked(false);
      handleSelectChange(lastSelectedId, false);
    }
    setShowNavigationWarning(false);
  };

  const renderNavItems = () => {
    let listitems = [];
    if (config && config.components) {
      config.components.forEach((comp) => {
        const { sidenav } = comp.config;
        if (sidenav && Array.isArray(sidenav) && sidenav.length > 0) {
          listitems = listitems.concat(sidenav);
        }
      });
    }

    listitems = listitems.sort((a, b) => a.order - b.order);

    return listitems.map((item) => {
      if (
        !(
          Object.keys(currentUserPermissions).length > 0 &&
          checkPermissions(item, currentUserPermissions)
        )
      ) {
        return null;
      }
      return (
        <SideNavigationItem
          data-testid="sideNavigationItem"
          key={item.id}
          className={
            isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
          }
          selected={selectedId === item.id}
          onClick={(evt) => {
            handleNavigationItemClick(evt);
          }}
          text={t(item.text)}
          icon={item.icon}
          id={item.id}
          tooltip={item.text}
          expanded
        >
          {item.items
            ?.filter((i) => checkPermissions(i, currentUserPermissions))
            ?.filter(({ featureFlag }) => !featureFlag || getFeatureToggle(featureFlag))
            .map((child) => (
              <SideNavigationSubItem
                key={child.text}
                selected={child.id === selectedId}
                onClick={handleNavigationItemClick}
                className={
                  isDisable(child) ? classes.disabled : `${classes.enabled} ${classes.customized}`
                }
                text={t(child.text)}
                icon={child.icon}
                id={child.id}
                data-id={child.id}
                data-testid="sideNavigationSubItem"
                title={t(child.text)}
              />
            ))}
        </SideNavigationItem>
      );
    });
  };

  return (
    <SideNavigation
      data-testid="sideNavigationPanel"
      key={showNavigationWarning}
      className={classes.sideNavigation}
      collapsed={!common.showMenu}
      selectedId={selectedId}
      noIcons={false}
      style={{ height: '100%', fontSize: '14px' }}
      onSelectionChange={(e) => {
        handleSelectChange(e.detail.item.id, isNavBlocked);
      }}
    >
      {renderNavItems()}
      {createPortal(
        <MessageBox
          type={MessageBoxTypes.Warning}
          titleText={t('Warning')}
          open={showNavigationWarning}
          onClose={handleNavigationWarningClose}
          actions={[navigationWarningButtons.CONFIRM, navigationWarningButtons.REJECT]}
          emphasizedAction={navigationWarningButtons.CONFIRM}
          style={{ maxWidth: '320px' }}
        >
          {navigationWarningMessage}
        </MessageBox>,
        document.body,
      )}
    </SideNavigation>
  );
}

SidePanel.propTypes = {
  common: PropTypes.object.isRequired,
};
SidePanel.defaultProps = {};

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
